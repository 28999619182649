import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// import "bootstrap/dist/css/bootstrap.min.css";  /原始bootstrap的css檔
import "bootstrap";
import "@/bootstrap.css";

import { createI18n } from "vue-i18n"; // 引入 Vue I18n
import zh from "./i18n/zh"; // 存放中文語系檔
import en from "./i18n/en"; // 存放英文語系檔

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEarthAsia,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
library.add(faEarthAsia, faEarthAmericas);

import { VueCookieNext } from "vue-cookie-next";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "zh", // set locale
  fallbackLocale: "zh", // set fallback locale
  messages: {
    zh: zh,
    en: en,
  },
  globalInjection: true,
  warnHtmlMessage: false,
});

import { CIcon } from "@coreui/icons-vue";
import { cilList, cilSearch, cilGlobeAlt, cilWarning } from "@coreui/icons";
const icons = {
  cilList,
  cilSearch,
  cilGlobeAlt,
  cilWarning,
};

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueCookieNext)
  .use(ElementPlus)
  .provide("icons", icons)
  .component("CIcon", CIcon)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
