<template>
  <div class="home">
    <!-- <div>
      <el-dialog v-model="showDialog" :title="dialogTitle" width="35%">
        <span class="statement" v-html="formattedDialogContent"></span>
      </el-dialog>
    </div> -->
    <img
      src="@/assets/homeImg/tirc_vision.webp"
      alt="tirc_vision"
      style="width: 80%"
    />
    <!-- about us -->
    <div class="textColumn">
      <div class="myH3">{{ $t("about") }}</div>
      <div class="myH5">
        {{ $t("aboutUsC") }}
      </div>
    </div>
    <!-- core team -->
    <div class="textColumn reveal">
      <div class="myH3">{{ $t("coreTeamT") }}</div>
      <img
        src="@/assets/homeImg/tirc_team.jpg"
        alt="tirc team"
        style="width: 100%; padding-bottom: 20px"
      />
      <div class="myH5">
        {{ $t("coreTeamC") }}
      </div>
    </div>

    <div class="textColumn reveal">
      <div class="myH3">{{ $t("boardofDirectorsT") }}</div>
      <div
        class="myH5"
        :style="{ 'text-align': locale == 'zh' ? 'center' : 'justify' }"
      >
        {{ $t("boardofDirectorsC") }}
      </div>

      <table
        class="setFontSize"
        style="margin: 30px auto; inline: height 1.2em"
      >
        <thead>
          <th class="col-3 col-md-3">{{ $t("boardOfDirectorsMembers") }}</th>
          <th class="col-3 col-md-4">{{ $t("boardOfDirectorsName") }}</th>
          <th class="col-6 col-md-5">{{ $t("boardOfDirectorsTitle") }}</th>
        </thead>

        <tbody>
          <tr v-for="item in boardOfDirectors" :key="item.id">
            <td>
              {{ $t(item.members) }}
            </td>
            <td>
              {{ $t(item.name) }}
            </td>
            <td v-html="$t(item.company)"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
table {
  text-align: center;
}

th,
td {
  border: 1px solid #cfcfcf;
  padding: 5px 16px;
  height: 70px;
}

th {
  background-color: #cfcfcf;
  font-size: 1.2rem;
}

.reveal {
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  opacity: 1;
}

.statement {
  font-size: 12pt;
}

@media (max-width: 500px) {
  .setFontSize {
    font-size: 0.7rem;
  }
  th,
  td {
    padding: 5px 10px;
  }
  th {
    font-size: 0.9rem;
  }
}
</style>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;
    // console.log(i + " elementTop " + elementTop);
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      // reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

export default {
  name: "HomeView",
  components: {},
  // data() {
  //   return {
  //     showDialog: true,
  //   };
  // },
  setup() {
    const boardOfDirectors = [
      {
        members: "chairman",
        name: "chairmanName",
        company: "chairmancCompany",
        title: "chairmanTitle",
      },
      {
        members: "director",
        name: "director1",
        company: "director1Company",
        title: "director1Title",
      },
      {
        members: "director",
        name: "director2",
        company: "director2Company",
        title: "director2Title",
      },
      {
        members: "director",
        name: "director3",
        company: "director3Company",
        title: "director3Title",
      },
      {
        members: "director",
        name: "director4",
        company: "director4Company",
        title: "director4Title",
      },
      {
        members: "supervisior",
        name: "supervisiorName",
        company: "supervisiorCompany",
        title: "",
      },
    ];

    const { locale } = useI18n();

    onMounted(() => {
      window.addEventListener("scroll", reveal);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", reveal);
    });
    return { boardOfDirectors, locale };
  },
  // computed: {
  //   formattedDialogContent() {
  //     return this.$t("dialogContent").replace(/\n/g, "<br>");
  //   },
  //   dialogTitle() {
  //     return this.$t("dialogTitle");
  //   },
  // },
  // methods: {
  //   openDialog() {
  //     this.showDialog = true;
  //   },
  //   closeDialog() {
  //     this.showDialog = false;
  //   },
  // },
};
</script>
