<template>
  <div class="wrapper">
    <header style="display: grid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid" style="flex-wrap: nowrap">
          <!-- logo -->
          <div class="logoimg col-xs-1">
            <router-link class="navbar-brand" to="/">
              <img
                src="./assets/tirc_web.svg"
                alt="tirc"
                style="height: 100%"
              />
            </router-link>
          </div>
          <!-- end of logo -->

          <div class="navSetRight">
            <div class="upper setPadding">
              <!-- language selector -->
              <font-awesome-icon
                v-if="locale == 'zh'"
                icon="fa-solid fa-earth-asia"
                style="font-size: 20px; margin: auto 5px"
              />
              <font-awesome-icon
                v-else
                icon="fa-solid fa-earth-americas"
                style="font-size: 20px; margin: auto 5px"
              />

              <span class="langSelector" style="margin-right: 20px">
                <select
                  v-model="$i18n.locale"
                  class="form-select-sm setLangFont"
                  aria-label=".form-select-sm"
                >
                  <option value="en">English</option>
                  <option value="zh">繁中</option>
                </select>
              </span>
              <!-- end of language selector -->

              <!-- search bar -->
              <div class="searchBar input-group-sm">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchTarget"
                  placeholder="Search"
                />
                <button
                  class="btn btn-outline-secondary"
                  style="padding: 1px 5px"
                  type="button"
                  id="button-addon2"
                  @click="goToSearchPage"
                >
                  <CIcon :icon="cilSearch" size="custom-size" width="20px" />
                </button>
              </div>
              <!-- end of search bar -->
            </div>

            <!-- nav bar -->
            <div class="bottom setPadding">
              <div
                class="offcanvas offcanvas-end"
                style="width: auto"
                tabindex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div class="offcanvas-header">
                  <div
                    class="offcanvas-title"
                    id="offcanvasNavbarLabel"
                    style="50px"
                  >
                    <img
                      src="./assets/tirc_web.svg"
                      alt="tirc"
                      style="height: 40px"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">
                  <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <!-- about -->
                    <li class="nav-item dropdown">
                      <a
                        :class="{
                          active:
                            currentRoute === 'visionMission' ||
                            currentRoute === 'memorabilia' ||
                            currentRoute === 'awardCertification' ||
                            currentRoute === 'affiliatedCompany',
                        }"
                        class="nav-link dropdown-toggle"
                        id="offcanvasNavbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ $t("about") }}
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="offcanvasNavbarDropdown"
                      >
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/visionMission"
                            :class="{
                              active: currentRoute === 'visionMission',
                            }"
                            >{{ $t("vision&Mission") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/Memorabilia"
                            :class="{ active: currentRoute === 'memorabilia' }"
                            >{{ $t("memorabilia") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/awardCertification"
                            :class="{
                              active: currentRoute === 'awardCertification',
                            }"
                            >{{ $t("awardCertification") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/affiliatedCompany"
                            :class="{
                              active: currentRoute === 'affiliatedCompany',
                            }"
                            >{{ $t("affiliatedCompany") }}</router-link
                          >
                        </li>
                        <!-- end of about -->
                      </ul>
                    </li>

                    <!-- product -->
                    <li class="nav-item dropdown">
                      <a
                        :class="{
                          active:
                            currentRoute === 'robotics' ||
                            currentRoute === 'automation' ||
                            currentRoute === 'healthcare' ||
                            currentRoute === 'service' ||
                            currentRoute === 'tpw1Description' ||
                            currentRoute === 'tgio1Description' ||
                            currentRoute === 'samrDescription' ||
                            currentRoute === 'hcdDescription' ||
                            currentRoute === 'spotDescription' ||
                            currentRoute === 'lrwa2Description' ||
                            currentRoute === 'lrwa1Description' ||
                            currentRoute === 'mrd1Description' ||
                            currentRoute === 'tba1Description' ||
                            currentRoute === 'cma1Description' ||
                            currentRoute === 'ama1Description' ||
                            currentRoute === 'mma1Description',
                        }"
                        class="nav-link dropdown-toggle"
                        id="offcanvasNavbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ $t("products") }}
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="offcanvasNavbarDropdown"
                      >
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/robotics"
                            :class="{
                              active:
                                currentRoute === 'robotics' ||
                                currentRoute === 'tpw1Description' ||
                                currentRoute === 'tgio1Description' ||
                                currentRoute === 'samrDescription' ||
                                currentRoute === 'hcdDescription' ||
                                currentRoute === 'spotDescription',
                            }"
                            >{{ $t("robotics") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/automation"
                            :class="{
                              active:
                                currentRoute === 'automation' ||
                                currentRoute === 'lrwa2Description' ||
                                currentRoute === 'lrwa1Description' ||
                                currentRoute === 'mrd1Description' ||
                                currentRoute === 'tba1Description',
                            }"
                            >{{ $t("automation") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/healthcare"
                            :class="{
                              active:
                                currentRoute === 'healthcare' ||
                                currentRoute === 'cma1Description' ||
                                currentRoute === 'ama1Description' ||
                                currentRoute === 'mma1Description',
                            }"
                            >{{ $t("healthcare") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            to="/service"
                            :class="{ active: currentRoute === 'service' }"
                            >{{ $t("service") }}</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <!-- end of product -->

                    <!-- solutions -->
                    <li class="nav-item dropdown">
                      <a
                        :class="{
                          active:
                            currentRoute === 'inVitroDiagonistic' ||
                            currentRoute === 'roboticWarehousing' ||
                            currentRoute === 'logisticsPacking' ||
                            currentRoute === 'inspectionRobot',
                        }"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ $t("solutions") }}
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{
                              active: currentRoute === 'inVitroDiagonistic',
                            }"
                            to="/InVitroDiagonistic"
                            >{{ $t("inVitroDiagonistic") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{
                              active: currentRoute === 'roboticWarehousing',
                            }"
                            to="/RoboticWarehousing"
                            >{{ $t("roboticWarehousing") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{
                              active: currentRoute === 'logisticsPacking',
                            }"
                            to="/LogisticsPacking"
                            >{{ $t("logisticsPacking") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{
                              active: currentRoute === 'inspectionRobot',
                            }"
                            to="/InspectionRobot"
                            >{{ $t("inspectionRobot") }}</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <!-- end of solutions -->

                    <!-- tech -->
                    <li class="nav-item">
                      <router-link
                        :class="{ active: currentRoute === 'technology' }"
                        class="nav-link"
                        aria-current="page"
                        to="/technology"
                        >{{ $t("technology") }}</router-link
                      >
                    </li>
                    <!-- end of tech -->

                    <!-- press -->
                    <li class="nav-item dropdown">
                      <a
                        :class="{
                          active:
                            currentRoute === 'newsList' ||
                            currentRoute === 'news' ||
                            currentRoute === 'videos',
                        }"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ $t("press") }}
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{ active: currentRoute === 'newsList' }"
                            to="/NewsList"
                            >{{ $t("news") }}</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            :class="{ active: currentRoute === 'videos' }"
                            to="/videos"
                            >{{ $t("videos") }}</router-link
                          >
                        </li>
                      </ul>

                      <!-- end of press -->
                    </li>

                    <!-- contact -->
                    <li class="nav-item">
                      <router-link
                        :class="{ active: currentRoute === 'contact' }"
                        class="nav-link"
                        aria-current="page"
                        to="/contact"
                        >{{ $t("contact") }}</router-link
                      >
                    </li>
                    <!-- end of contact -->
                  </ul>
                </div>
              </div>
              <!-- end of Nav bar -->

              <!-- hamberger menu -->
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <!-- end of hamberger menu -->
            </div>
          </div>
        </div>
      </nav>

      <!--linear-gradient-->
      <div class="row gradientStyle">
        <div class="col-8" style="background-color: #cccccc; padding: 0"></div>
        <div class="col-2 g1"></div>
        <div class="col-2 g2"></div>
      </div>
      <!--end of linear-gradient-->
    </header>

    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.fullPath" />
        </transition>
      </router-view>
    </div>

    <footer>
      <div style="display: flex; padding: 8px 35%; justify-content: center">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 3%;
          "
        >
          <div style="width: 5%; min-width: 70px">
            <router-link class="navbar-brand" to="/" style="padding: 0">
              <img
                src="./assets/tirc_SVG.svg"
                alt="tirc"
                style="height: 100%"
              />
            </router-link>
          </div>
        </div>

        <div
          style="
            display: flex;
            min-width: 180px;
            margin-left: 3%;
            justify-content: center;
            padding-top: 20px;
          "
        >
          <span>
            <a
              href="https://www.104.com.tw/company/1a2x6bji5p"
              target="_blank"
              >{{ $t("jobPosition") }}</a
            >
          </span>
          <span style="margin: 0 10px">|</span>
          <span>
            <a href="https://mis.tirc.com.tw/aw/login.html" target="_blank">{{
              $t("employees")
            }}</a>
          </span>
        </div>
      </div>
      <div class="copyRight">
        © Copyright 2022 Taiwan Intelligent Robotics Company, Ltd.
      </div>
    </footer>

    <div
      class="modal fade"
      id="errorModal"
      tabindex="-1"
      aria-labelledby="errorModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-danger bg-gradient text-white">
            <h5 class="modal-title" id="errorModalTitle">錯誤</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="display: flex; align-items: center">
            <CIcon
              :icon="cilWarning"
              style="float: left; width: 40px; color: red; margin-left: 15px"
            />
            <div class="my-auto" style="float: left">
              <p
                id="errorModalMsg"
                class="my-auto"
                style="margin-bottom: 0; margin-left: 15px"
              ></p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Sans+TC:wght@300&display=swap");
#app {
  // font-family: "Montserrat", sans-serif;
  // font-family: "Noto Sans TC", sans-serif;
  font-family: Montserrat, Helvetica, Arial, Noto Sans TC, "LiHei Pro",
    "黑體-繁", "微軟正黑體", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--bs-secondary);
}

// 將footer置底
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 350px;
  width: 100%;
}

header {
  box-shadow: 0px 12px 12px -14px #af9f91;
  font-size: 18px;

  .logoimg {
    height: 80px;
  }

  .navSetRight {
    flex-direction: column;
    display: flex;

    .upper {
      display: flex;
      justify-content: flex-end;
    }

    .bottom {
      display: flex;
      justify-content: flex-end;
    }

    .setPadding {
      padding: 5px;
    }

    .searchBar {
      margin: 0;
      display: flex;
    }
  }

  .gradientStyle {
    height: 4px;
    width: 100%;
    padding: 0;
    margin: 0%;
    position: relative;
    bottom: 0;

    .g1 {
      background: linear-gradient(to right, #cccccc, #f29100, #ffd528);
      padding: 0;
    }
    .g2 {
      background: linear-gradient(to right, #ffd528, #b2cf6d, #0dcaf0);
      padding: 0;
    }
  }

  nav {
    padding: 30px;

    a {
      font-weight: normal;
      font-size: 1.1rem;
      color: #2c3e50;
      text-decoration: none;

      &.router-link-exact-active {
        color: var(--bs-primary);
        text-decoration: none;
      }
    }
  }
}

.content {
  flex: 1;
  // white-space: wrap;
  width: 90%;
  margin: 0 5% 50px;
  // hyphens: auto;
}

footer {
  background-color: #f3f3f3;
  padding: 10px;
  font-size: 14px;

  a {
    font-weight: normal;
    color: #2c3e50;
    text-decoration: none;
  }
  a:hover {
    color: var(--bs-primary);
  }
}

.textColumn {
  margin: 30px 10%;

  .myH3 {
    font-size: 2.3rem;
    line-height: 2.5;
    color: var(--bs-primary);
    text-align: center;
  }

  .myH5 {
    font-size: 1rem;
    line-height: 1.8;
    text-align: justify;
  }
}

/* nav bar 的 menu 自動顯示與transition動畫 開始 */
/* menu bar 自動顯示 */
.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
  padding: 0.5em 0;
  max-height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
/* menu bar 自動顯示結束 */
/*menu bar 動畫區 */
.navbar-nav li:hover > a:after {
  transform: rotate(180deg);
}

.navbar-nav li > a:after {
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.3s;
}

.nav-link + ul.dropdown-menu {
  display: block;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.15s ease-out;
  transition-delay: 0.3s;
  border: 0 solid rgba(0, 0, 0, 0.15);
}
/* nav bar 的 menu 自動顯示與transition動畫 結束 */

/* 轉頁動畫 開始 */
// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* 轉頁動畫 結束 */

.iconSize {
  width: 20px;
}

@media (max-width: 500px) {
  header {
    display: flex;
    .logoimg {
      height: 50px;
    }

    nav {
      padding: 20px;
    }
  }
}
</style>

<script>
import { watch, ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { cilList, cilSearch, cilGlobeAlt, cilWarning } from "@coreui/icons";
import { useCookie } from "vue-cookie-next";
import { Modal } from "bootstrap";
import { useRoute } from "vue-router";

function installErrorModal() {
  return new Modal(document.getElementById("errorModal"));
}

var errorModal;

export default {
  setup() {
    const route = useRoute();

    const currentRoute = computed(() => {
      return route.name;
    });

    //watch(currentRoute, () => {
    // console.log("currentRoute", route.name);
    //});

    const searchTarget = ref("");

    // 修改網頁標題
    const { t, locale } = useI18n();

    const cookie = useCookie();

    let cache = cookie.getCookie("locale");
    if (cache != null) {
      locale.value = cache;
    }

    document.title = t("pageTitle");

    watch(locale, () => {
      document.title = t("pageTitle");
      cookie.setCookie("locale", locale.value);
    });

    const goToSearchPage = () => {
      if (searchTarget.value != null && searchTarget.value.length > 0) {
        window.location.href = `/search#gsc.q=${searchTarget.value}`;
        //router.push({ path: "/search", query: { q: `${searchTarget.value}` } });
      } else {
        $("#errorModalMsg").html("請輸入搜尋字串");
        errorModal.show();
        //alert("請輸入搜尋字串");
      }
    };

    onMounted(() => {
      errorModal = installErrorModal();
    });

    return {
      currentRoute,
      goToSearchPage,
      searchTarget,
      cilList,
      cilSearch,
      cilGlobeAlt,
      cilWarning,
      locale,
    };
  },
};
</script>
