import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/VisionMission",
    name: "visionMission",
    component: () =>
      import(
        /* webpackChunkName: "visionMission" */
        "../views/VisionMission.vue"
      ),
  },
  {
    path: "/Memorabilia",
    name: "memorabilia",
    component: () =>
      import(
        /* webpackChunkName: "memorabiliaView" */
        "../views/MemorabiliaView.vue"
      ),
  },
  {
    path: "/AwardCertification",
    name: "awardCertification",
    component: () =>
      import(
        /* webpackChunkName: "AwardCertification" */
        "../views/AwardCertification.vue"
      ),
  },
  {
    path: "/AffiliatedCompany",
    name: "affiliatedCompany",
    component: () =>
      import(
        /* webpackChunkName: "AffiliatedCompany" */
        "../views/AffiliatedCompany.vue"
      ),
  },
  {
    path: "/Robotics",
    name: "robotics",
    component: () =>
      import(
        /* webpackChunkName: "RoboticsView" */
        "../views/RoboticsView.vue"
      ),
  },
  {
    path: "/Tpw1Description",
    name: "tpw1Description",
    component: () =>
      import(
        /* webpackChunkName: "Tpw1Description" */
        "../views/Tpw1Description.vue"
      ),
  },
  {
    path: "/Tgio1Description",
    name: "tgio1Description",
    component: () =>
      import(
        /* webpackChunkName: "Tgio1Description" */
        "../views/Tgio1Description.vue"
      ),
  },
  {
    path: "/SamrDescription",
    name: "samrDescription",
    component: () =>
      import(
        /* webpackChunkName: "SamrDescription" */
        "../views/SamrDescription.vue"
      ),
  },
  {
    path: "/HcdDescription",
    name: "hcdDescription",
    component: () =>
      import(
        /* webpackChunkName: "HcdDescription" */
        "../views/HcdDescription.vue"
      ),
  },
  {
    path: "/SpotDescription",
    name: "spotDescription",
    component: () =>
      import(
        /* webpackChunkName: "SpotDescription" */
        "../views/SpotDescription.vue"
      ),
  },
  {
    path: "/Automation",
    name: "automation",
    component: () =>
      import(
        /* webpackChunkName: "AutomationView" */
        "../views/AutomationView.vue"
      ),
  },
  {
    path: "/Lrwa2Description",
    name: "lrwa2Description",
    component: () =>
      import(
        /* webpackChunkName: "Lrwa2Description" */
        "../views/Lrwa2Description.vue"
      ),
  },
  {
    path: "/Lrwa1Description",
    name: "lrwa1Description",
    component: () =>
      import(
        /* webpackChunkName: "Lrwa1Description" */
        "../views/Lrwa1Description.vue"
      ),
  },
  {
    path: "/Mrd1Description",
    name: "mrd1Description",
    component: () =>
      import(
        /* webpackChunkName: "Mrd1Description" */
        "../views/Mrd1Description.vue"
      ),
  },
  {
    path: "/Tba1Description",
    name: "tba1Description",
    component: () =>
      import(
        /* webpackChunkName: "Tba1Description" */
        "../views/Tba1Description.vue"
      ),
  },
  {
    path: "/Healthcare",
    name: "healthcare",
    component: () =>
      import(
        /* webpackChunkName: "HealthcareView" */
        "../views/HealthcareView.vue"
      ),
  },
  {
    path: "/Cma1Description",
    name: "cma1Description",
    component: () =>
      import(
        /* webpackChunkName: "Cma1Description" */
        "../views/Cma1Description.vue"
      ),
  },
  {
    path: "/Ama1Description",
    name: "ama1Description",
    component: () =>
      import(
        /* webpackChunkName: "Ama1Description" */
        "../views/Ama1Description.vue"
      ),
  },
  {
    path: "/Mma1Description",
    name: "mma1Description",
    component: () =>
      import(
        /* webpackChunkName: "Mma1Description" */
        "../views/Mma1Description.vue"
      ),
  },
  {
    path: "/Service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "ServiceView" */ "../views/ServiceView.vue"),
  },
  {
    path: "/InVitroDiagonistic",
    name: "inVitroDiagonistic",
    component: () =>
      import(
        /* webpackChunkName: "InVitroDiagonistic" */
        "../views/InVitroDiagonistic.vue"
      ),
  },
  {
    path: "/RoboticWarehousing",
    name: "roboticWarehousing",
    component: () =>
      import(
        /* webpackChunkName: "RoboticWarehousing" */
        "../views/RoboticWarehousing.vue"
      ),
  },
  {
    path: "/LogisticsPacking",
    name: "logisticsPacking",
    component: () =>
      import(
        /* webpackChunkName: "LogisticsPacking" */
        "../views/LogisticsPacking.vue"
      ),
  },
  {
    path: "/InspectionRobot",
    name: "inspectionRobot",
    component: () =>
      import(
        /* webpackChunkName: "InspectionRobot" */
        "../views/InspectionRobot.vue"
      ),
  },
  {
    path: "/Technology",
    name: "technology",
    component: () =>
      import(
        /* webpackChunkName: "technologyView" */
        "../views/TechnologyView.vue"
      ),
  },
  {
    path: "/NewsList",
    name: "newsList",
    component: () =>
      import(
        /* webpackChunkName: "NewsList" */
        "../views/NewsList.vue"
      ),
  },
  // 利用:id的方式抓取需要前往的頁面編號
  {
    path: "/News/:id",
    name: "news",
    component: () =>
      import(
        /* webpackChunkName: "News" */
        "../views/NewsView.vue"
      ),
  },
  {
    path: "/Videos",
    name: "videos",
    component: () =>
      import(
        /* webpackChunkName: "VideosView" */
        "../views/VideosView.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/SearchView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
